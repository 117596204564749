









































import { Component, Ref } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import FacilityV2Service from '@common-src/service/facility-v2';
import ThingsTemplateService from '@common-src/service/things-template';
import FormComponent from '@common-src/mixins/form-component';
import { FacilityAttributeEntityModel } from '@common-src/entity-model/facility-attribute-entity';
import { facilityAttributeDataTypeFilter } from '@common-src/filter';
import { FacilityV2QueryModel } from '@common-src/entity-model/facility-v2-entity';
import SignService from '@common-src/service/sign-v2';
import { ThingsSyncQueryModel } from '@common-src/entity-model/things-template-entity';
import { FormControlType } from '@common-src/model/form-control';
import { SyncType, ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';

@Component
export default class ThingsSyncPreviewDialog extends TableComponent<any, any> {
    dialogVisible: boolean = false;
    preSyncListDataResult: Array<any> = null;

    remoteUrl: string = null;
    targetGroup: string = null;
    token: string;
    syncType: SyncType = null;
    thingsEntityType: ThingsEntityType;
    thingsFunctionType: ThingsFunctionType;

    get IsUpload() {
        return this.syncType === SyncType.UPLOAD;
    }

    created() {
        this.tableColumns = [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '对比结果',
                dataIndex: 'result',
                scopedSlots: { customRender: 'result' }
            },
            {
                title: '原因',
                dataIndex: 'desc'
            }
        ];
    }

    get DialogTitle() {
        let dialogTitle = null;
        let uploadOrDownloadText = null;
        if (this.syncType === SyncType.UPLOAD) {
            uploadOrDownloadText = '上传';
        } else {
            uploadOrDownloadText = '下载';
        }
        if (this.thingsEntityType === ThingsEntityType.TEMPLATE) {
            dialogTitle = '物模板' + uploadOrDownloadText;
        } else if (this.thingsEntityType === ThingsEntityType.FUNCTION) {
            dialogTitle = '物功能' + uploadOrDownloadText;
            switch (this.thingsFunctionType) {
                case ThingsFunctionType.ATTRIBUTE:
                    dialogTitle += '-属性';
                    break;
                case ThingsFunctionType.EVENT:
                    dialogTitle += '-事件';
                    break;
                case ThingsFunctionType.METHOD:
                    dialogTitle += '-服务';
                    break;
            }
        }
        return dialogTitle;
    }

    get CanSync() {
        if (this.preSyncListDataResult && this.preSyncListDataResult.length > 0 && _.filter(this.preSyncListDataResult, item => !item.result).length === 0) {
            return true;
        }
        return false;
    }
    get SuccessCount() {
        return _.filter(this.preSyncListDataResult, item => item.result).length;
    }
    get FailCount() {
        return _.filter(this.preSyncListDataResult, item => !item.result).length;
    }

    dialogOpen(remoteUrl: string, targetGroup: string, thingsFunctionType: ThingsFunctionType, queryModel: ThingsSyncQueryModel, listData: Array<any>) {
        this.listData = listData;
        this.remoteUrl = remoteUrl;
        this.targetGroup = targetGroup;
        this.token = queryModel.token;
        this.syncType = queryModel.syncType;
        this.thingsEntityType = queryModel.thingsEntityType;
        this.thingsFunctionType = thingsFunctionType;
        this.dialogVisible = true;
        this.listLoading = true;
        ThingsTemplateService.sync(remoteUrl, this.targetGroup, this.token, this.syncType, this.thingsFunctionType, this.thingsEntityType, listData, true).then(res => {
            this.preSyncListDataResult = res;
        }).finally(() => {
            this.listLoading = false;
        });
    }

    dialogOK() {
        const confirm = this.$confirm({
            title: '确认同步',
            onOk: () => {
                this.listLoading = true;
                ThingsTemplateService.sync(this.remoteUrl, this.targetGroup, this.token, this.syncType, this.thingsFunctionType, this.thingsEntityType, this.listData, false).then(res => {
                    this.$emit('dialogOk');
                    this.dialogClose();
                }).finally(() => {
                    this.listLoading = false;
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }

    dialogClose(): void {
        this.dialogVisible = false;
    }

    async loginClick() {

    }
}
