





































































































































































































































import { Component, Ref } from 'vue-property-decorator';
import TransformComponent from '@common-src/mixins/transform-component';
import ThingsTemplateService from '@common-src/service/things-template';
import FormComponent from '@common-src/mixins/form-component';
import { FacilityAttributeEntityModel } from '@common-src/entity-model/facility-attribute-entity';
import { facilityAttributeDataTypeFilter } from '@common-src/filter';
import { FacilityV2QueryModel } from '@common-src/entity-model/facility-v2-entity';
import SignService from '@common-src/service/sign-v2';
import { ThingsSyncQueryModel } from '@common-src/entity-model/things-template-entity';
import { FormControlType } from '@common-src/model/form-control';
import ThingsSyncPreviewDialog from './things-sync-preview-dialog.vue';
import { SyncType, ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';
import { thingsModelFunctionList } from '@common-src/filter/things-model-function';

@Component({
    components: {
        'things-sync-preview-dialog': ThingsSyncPreviewDialog
    }
})
export default class ThingsSyncDialog extends TransformComponent {
    @Ref('remoteForm')
    jtlForm: FormComponent<any>;
    debounceReload: Function;
    sourceGroupOptions: Array<any> = [];
    targetGroupOptions: Array<any> = [];
    thingsFunctionType: ThingsFunctionType;
    SyncType = SyncType;

    get IsTemplate() {
        return _.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.TEMPLATE;
    }

    get ListData() {
        return this.listData;
    }

    get ThingsModelFunctionList() {
        return _.take(thingsModelFunctionList, 3);
    }

    get CurrentSyncType() {
        return _.get(this.queryModel, 'syncType');
    }

    get ThingsTypeTitle() {
        let title = null;
        if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.TEMPLATE) {
            title = '物模板';
        } else if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.FUNCTION) {
            title = '物功能';
        }
        return title;
    }

    get DialogTitle() {
        let dialogTitle = null;
        let uploadOrDownloadText = null;
        if (_.get(this.queryModel, 'syncType') === SyncType.UPLOAD) {
            uploadOrDownloadText = '上传';
        } else {
            uploadOrDownloadText = '下载';
        }
        if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.TEMPLATE) {
            dialogTitle = '物模板' + uploadOrDownloadText;
        } else if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.FUNCTION) {
            dialogTitle = '物功能' + uploadOrDownloadText;
            switch (this.thingsFunctionType) {
                case ThingsFunctionType.ATTRIBUTE:
                    dialogTitle += '-属性';
                    break;
                case ThingsFunctionType.EVENT:
                    dialogTitle += '-事件';
                    break;
                case ThingsFunctionType.METHOD:
                    dialogTitle += '-服务';
                    break;
            }
        }
        return dialogTitle;
    }

    created() {
        this.debounceReload = _.debounce(this.reloadClick.bind(this), 500);
        this.isAutoQuery = false;
        this.pageSize = 999;
        this.initTable({
            listFunc: ThingsTemplateService.syncQuery,
            queryModel: new ThingsSyncQueryModel()
        });
    }

    get AllLeftCheck() {
        if (!this.listData || this.listData.length === 0) {
            return false;
        }
        return _.filter(this.listData, item => item.check).length === this.listData.length;
    }

    set AllLeftCheck(val: boolean) {
        _.forEach(this.listData, item => {
            item.check = val;
        });
    }
    get AllRightCheck() {
        if (!this.rightDataList || this.rightDataList.length === 0) {
            return false;
        }
        return _.filter(this.rightDataList, item => item.check).length === this.rightDataList.length;
    }

    set AllRightCheck(val: boolean) {
        _.forEach(this.rightDataList, item => {
            item.check = val;
        });
    }

    dialogOpen(syncType: SyncType, thingsEntityType: ThingsEntityType, thingsFunctionType?: ThingsFunctionType) {
        this.thingsFunctionType = thingsFunctionType;
        this.queryModel = new ThingsSyncQueryModel();
        this.queryModel.syncType = syncType;
        this.queryModel.thingsEntityType = thingsEntityType;
        this.queryModel.functionType = _.upperCase(thingsFunctionType);
        if (this.queryModel.thingsEntityType === ThingsEntityType.FUNCTION) {
            this.queryModel.sourceGroupIds = thingsFunctionType;
        }
        this.dialogVisible = true;
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems: Array<any> = [
                    {
                        key: 'url',
                        label: '地址',
                        type: FormControlType.TEXT,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' },
                        required: true
                    },
                    {
                        key: 'username',
                        label: '账号',
                        type: FormControlType.TEXT,
                        required: true,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                    },
                    {
                        key: 'password',
                        label: '密码',
                        type: FormControlType.TEXT,
                        inputType: 'password',
                        required: true,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                    }
                ];
                if (thingsEntityType === ThingsEntityType.TEMPLATE) {
                    controlItems.push({
                        key: 'targetGroup',
                        label: '目标分组',
                        type: FormControlType.TREE_SELECT,
                        required: false,
                        disabled: false,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                    });
                }
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK() {
        if (!this.jtlForm) {
            return;
        }
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                let selectedData = _.filter(this.CurrentSyncType ? this.listData : this.rightDataList, item => item.check);
                if (!selectedData || selectedData.length === 0) {
                    this.showMessageWarning('请选择要同步的数据');
                    reject('请选择要同步的数据');
                    return;
                }
                if (this.queryModel.thingsEntityType === ThingsEntityType.TEMPLATE && !this.jtlForm.formModel.targetGroup) {
                    this.showMessageWarning('请选择目标分组');
                    return;
                }
                (this.$refs.thingsSyncPriviewDialog as ThingsSyncPreviewDialog).dialogOpen(this.jtlForm.formModel.url, this.jtlForm.formModel.targetGroup, this.queryModel.sourceGroupIds, this.queryModel, selectedData);
            }).catch(err => {
                reject();
                throw err;
            });
        });
    }

    syncSuccess() {
        this.showMessageSuccess('同步成功');
        this.reloadClick();
        this.$emit('dialogOK');
        // this.dialogClose();
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = null;
        // this.queryModel = null;
        this.dialogVisible = false;
    }

    async loginClick() {
        if (this.queryModel.token) {
            this.listData = [];
            this.rightDataList = [];
            this.queryModel.token = null;
            // this.queryModel = new ThingsSyncQueryModel();
            _.forEach(this.jtlForm.formControlList, item => {
                item.disabled = false;
            });
            return;
        }
        let validateResult = false;
        try {
            validateResult = await this.jtlForm.validate();
        } catch (e) {
            return false;
        }
        if (validateResult) {
            const remoteUrl = this.jtlForm.formModel.url;
            // const remoteUrl = 'http://172.21.82.166:8080';
            return SignService.remoteLogin(remoteUrl, this.jtlForm.formModel.username, this.jtlForm.formModel.password)
                .then(res => {
                    this.queryModel.token = _.get(res, 'token');
                    this.queryModel.url = this.jtlForm.formModel.url;
                    _.forEach(this.jtlForm.formControlList, item => {
                        if (item.key !== 'targetGroup') {
                            item.disabled = true;
                        }
                    });
                    if (this.IsTemplate) {
                        ThingsTemplateService.getSysnGroup(this.jtlForm.formModel.url, this.queryModel.token).then(groupRes => {
                            this.sourceGroupOptions = _.get(groupRes, 'sourceGroups');
                            this.targetGroupOptions = _.get(groupRes, 'targetGroups');
                            if (this.queryModel.syncType === SyncType.UPLOAD) {
                                (this.jtlForm as any).formControlList[3].options = this.targetGroupOptions;
                            } else {
                                (this.jtlForm as any).formControlList[3].options = this.sourceGroupOptions;
                            }
                        });
                    }
                    this.reloadClick();
                })
                .catch(err => {
                    throw err;
                });
        }
    }

    reloadClick() {
        this.getList().then(res => {
            this.listData = _.map(_.get(res, 'sourceData'), item => { item.check = false; return item; });
            this.rightDataList = _.map(_.get(res, 'targetData'), item => { item.check = false; return item; });
        }).catch(err => {
            this.listData = null;
            this.rightDataList = null;
        });
    }
}
