var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: _vm.DialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "things-sync-preview-dialog form-edit-component-large transform-dialog",
        },
        [
          _c(
            "div",
            { staticClass: "presync-result" },
            [
              _c("a-icon", {
                class:
                  _vm.FailCount > 0 || _vm.SuccessCount === 0
                    ? "color-fail"
                    : "color-green",
                attrs: { type: "exclamation-circle" },
              }),
              _vm._v(" 对比结果: 成功匹配"),
              _c("span", { staticClass: "color-green" }, [
                _vm._v(_vm._s(_vm.SuccessCount)),
              ]),
              _vm._v("个, 匹配失败"),
              _c("span", { staticClass: "color-fail" }, [
                _vm._v(_vm._s(_vm.FailCount)),
              ]),
              _vm._v("个 "),
              _vm.IsUpload
                ? _c(
                    "span",
                    { staticClass: "pull-right" },
                    [
                      _vm._v(" 从本地 "),
                      _c("a-icon", {
                        staticClass: "color-green",
                        attrs: { type: "rise" },
                      }),
                      _vm._v(" " + _vm._s(_vm.remoteUrl) + " "),
                    ],
                    1
                  )
                : _c(
                    "span",
                    { staticClass: "pull-right" },
                    [
                      _vm._v(" 从" + _vm._s(_vm.remoteUrl) + " "),
                      _c("a-icon", {
                        staticClass: "color-green",
                        attrs: { type: "fall" },
                      }),
                      _vm._v(" 本地 "),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.preSyncListDataResult,
              pagination: false,
              scroll: _vm.tableScroll,
              "row-key": "identifier",
              size: _vm.TableSize,
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u([
              {
                key: "result",
                fn: function (text, record) {
                  return [
                    record.result
                      ? _c("a-icon", {
                          staticStyle: { color: "green" },
                          attrs: { type: "check" },
                        })
                      : _c("a-icon", {
                          staticStyle: { color: "red" },
                          attrs: { type: "close" },
                        }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.CanSync,
                "click-prop": _vm.dialogOK.bind(this),
              },
            },
            [_vm._v("确认同步")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }